import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NetworkService } from './core/services/network.service';
import { RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgIf, RouterOutlet]
})
export class AppComponent {
  title = 'gobakery-pwa';
  onlineStatus: boolean | undefined;
  private networkStatusSubscription: Subscription | undefined;
  
  constructor(private networkService: NetworkService) {}
  
  ngOnInit(): void {
    this.networkStatusSubscription = this.networkService.onlineStatus$.subscribe(status => {
      this.onlineStatus = status;
      // if (status) {
      //   console.log("Estás en línea");
      // } else {
      //   console.log("Estás fuera de línea");
      // }
    });
  }

  ngOnDestroy() {
    if (this.networkStatusSubscription) {
      this.networkStatusSubscription.unsubscribe();
    }
  }
}
