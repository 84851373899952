import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private path = environment.midUrl;

  constructor(
    private httpClient: HttpClient,
    private _ngZone: NgZone,
    private router: Router,
  ) { }

  public signOutExternal = () => {
    this._ngZone.run(() => {
      this.router.navigate(['/']).then(() => window.location.reload());
    });
  }

  LoginWithGoogle(credentials: string): Observable<any> {
    const header = new HttpHeaders().set('Content-type', 'application/json');
    return this.httpClient.post(this.path.loginWithGoogle, JSON.stringify({ credential: credentials }), { headers: header });
  }
}
