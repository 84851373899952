const API_BBM = 'https://api-dev.gobakery.cl';

export const environment = {
  production: false,
  midUrl: {
    getBusiness: `${API_BBM}/business/actives/:companyId`,
    getDealers: `${API_BBM}/dealers/business/:business/company/:companyId`,
    getDealersByHolding: `${API_BBM}/dealers/business/:business/company/:company/holding/:holdingId`,
    getBussinesByCompany: `${API_BBM}/business/actives/:companyId`,
    getActiveDealers: `${API_BBM}/dealers/:businessId/:companyId`,
    getDispatches: `${API_BBM}/dispatches/retrieve/:business/:dealer/:lap/:date/:companyId/:userId`,
    getDispatchesWithHolding: `${API_BBM}/dispatches/retrieve/:business/:dealer/:holding/:lap/:date/:company/:userId`,
    retrieveDispatch: `${API_BBM}/dispatches/retrieve/:businessId/:dealerId/:lap/:createDate/:companyId/:userId/:holdingId`,
    getDispatchesWithHeading: `${API_BBM}/dispatches/heading/:business/:dealer/:heading/:date/:company`,
    getSummary: `${API_BBM}/dispatches/summary/:business/:dealer/:date/:companyId/:userId`,
    getSummaryWithHolding: `${API_BBM}/dispatches/summary/holding/:business/:dealer/:date/:companyId/:holding/:userId`,
    saveDispatches: `${API_BBM}/dispatches/:dispatchId`,
    login: `${API_BBM}/auth/login`,
    loginWithGoogle: `${API_BBM}/auth/loginWithGoogle`,
    register: `${API_BBM}/auth/register`,
    getHoldings: `${API_BBM}/holding/activesByCompany/:companyId`,
    getHoldingsByDealer: `${API_BBM}/holding/findByDealer/:dealerId/:companyId`,
    getClients: `${API_BBM}/clients/:companyId/:business/:dealerId/:profile`,
    getClientById: `${API_BBM}/clients/:clientId`,
    getClientsByHolding: `${API_BBM}/clients/byHolding/:holdingId`,
    saveClients: `${API_BBM}/clients/create`,
    updateClients: `${API_BBM}/clients/:clientId`,
    saveClientPrices: `${API_BBM}/clients/:clientId`,
    deleteClient: `${API_BBM}/clients/:clientId`,
    disableClient: `${API_BBM}/clients/:clientId`,
    productsByBusiness: `${API_BBM}/products/findByBusiness/:businessId`,
    generateReport: `${API_BBM}/report/build/google/spreadsheet/:date/:companyId`,
    getCompanies: `${API_BBM}/company/findAll`,
    saveOrderRequest: `${API_BBM}/orders/create`,
    saveClientsPrices: `${API_BBM}/holding/updateClientsPrices/:holdingId`,
    saveClientCasino: `${API_BBM}/clients/updateClientCasino/:clientId`,
    deleteProduct: `${API_BBM}/products/:productId`,
    disableProduct: `${API_BBM}/products/:productId`,
    createProduct: `${API_BBM}/products/create`,
    updateProduct: `${API_BBM}/products/:productId`,
    getPdfSummary: `${API_BBM}/dispatches/summary/pdf`,
  },
  clientId:
    '664344623444-tdt76mf2ueuoodoo6rva91l0b9728muu.apps.googleusercontent.com',
};
