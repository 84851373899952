import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, fromEvent, throwError } from 'rxjs';
import { tap, catchError, switchMap, take } from 'rxjs/operators';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  private onlineEvent$: Observable<Event>;
  private offlineEvent$: Observable<Event>;

  constructor() {
    this.onlineEvent$ = fromEvent(window, 'online');
    this.offlineEvent$ = fromEvent(window, 'offline');
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          // Almacenar en caché o hacer algo con la respuesta
          localStorage.setItem(req.url, JSON.stringify(event.body));
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (!navigator.onLine) {
          console.log('No hay conexión a Internet. Esperando reconexión...');

          /* // Intentar obtener datos del caché
          const cachedData = localStorage.getItem(req.url);
          if (cachedData) {
            console.log('Devolviendo datos en caché');
            return of(
              new HttpResponse({ status: 200, body: JSON.parse(cachedData) })
            );
          } */

          // Esperar a que se restaure la conexión y luego reintentar
          return this.onlineEvent$.pipe(
            take(1),
            switchMap(() => {
              console.log('Conexión restaurada. Reintentando petición...');
              return next.handle(req);
            })
          );
        } else {
          // Si hay conexión pero aún así hay un error, lo propagamos
          return throwError(() => error);
        }
      })
    );
  }
}
